.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #FFFFFF !important;
  }


@media screen and (max-width:768px){
  .no-data-cell{
    width: 250px;
  }
}
